.chatPageContainer {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  width: 100vw;
  height: calc(100vh - 175px);
  gap: 20px;
  padding: 0 80px;

  @media (max-width: 768px) {
    padding: 0 10px;
  }
}

.chatPanelContainer {
  flex: 1;
  height: 100%;

  @media (max-width: 768px) {
    width: 100%;
  }
}

.mobileNavButton {
  display: flex;
  align-items: center;

  .iconify {
    margin: 0 4px;
    color: white;
  }

  @media (min-width: 769px) {
    display: none;
  }
}
