.phoneNumbers {
  margin-top: 20px;
  width: 60%;
  align-self: flex-start;
}

.radioContainer {
  max-width: 160px;
  padding: 0px 10px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  background-color: #1c1f3c;
}

.radio {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.checkBox {
  margin-top: 10px;
  width: 300px;
}

.checkBox span {
  font-size: 14px;
}

.checkBox input {
  transform: scale(1.5);
  cursor: pointer;
  font-size: 2rem;
  margin-left: 10px;
}
