.truncated-text {
  cursor: pointer;
  position: relative;
}

.truncated-text:hover::after {
  content: attr(title);
  position: absolute;
  left: 0;
  top: 100%;
  white-space: nowrap;
  background-color: #000;
  color: #fff;
  padding: 5px;
  border-radius: 3px;
  z-index: 1;
}
