.BotDisplayContainer {
  width: 100%;
  height: 100%;
  display: block;
  overflow: hidden;
  font-family: "Roboto", Helvetica;
  display: inline;
}

.dropdown-toggle {
  background-color: transparent !important;
  border: none;
  &:hover {
    color: #19dccd;
    background: #262944 !important;
  }
  &:active {
    background: transparent !important;
  }
  &:focus {
    background: transparent;
  }
}

.dropdown-menu {
  background-color: #1c1f3c;
  border: none;
  /* background-color: rgb(255, 255, 255, 0.9) !important;
    &:hover {
        background: rgb(255, 255, 255, 0.9) !important;
    }
    &:active {
        background: rgb(255, 255, 255, 0.9) !important;
    }
    &:focus {
        background: rgb(255, 255, 255, 0.9);
    } */
}

.AddBotButton {
  background-color:rgb(89, 81, 163);
  color: white;
  border: none;
  border-radius: 6px;
  justify-content: center;
  text-align: center;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  &:hover {
    background-color:rgb(64, 76, 247);
    border-color: #535896;
  }
}

.BotsHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 2%;
  padding-left: 1%;
  align-items: center;
  
  @media (max-width: 480px) {
      flex-direction: column;
      align-items: flex-start;
    }
}

.BotsList {
  overflow-y: auto;
}

::-webkit-scrollbar {
  width: 1em;
}

::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #2a2f62;
  border-radius: 10px;
}

.BotsHeaderLeft {
  display: inline-flex;
  align-items: center;
}

.BotTitle {
  font-size: 2em;
  font-weight: bold;
  margin-right: 0.5em;
}

.BreakStyle {
  margin-top: 0.4em;
  margin-bottom: 0.6em;
}

.BotLayout {
  height: 100%;
  width: 100%;
  display: inline-block;
  padding-left: 1%;
  padding-right: 1%;
}

.Bot {
  display: inline-block;
  min-width: 48%;
  max-width: 100%;
  height: 100%;
  margin-right: 1.5em;
  margin-bottom: 0.5em;
}

.OperationTitle {
  display: inline-block;
  width: 90%;
  color: #19dccd;
  background-color: transparent !important;
  font-size: 1.6em;
  font-weight: bold;
  padding-bottom: 0.5%;
  margin-right: 5%;
}

.OperationDates {
  padding-left: 2%;
  display: inline-block;
  color: white;
  font-size: 0.7em;
}

.BotList {
  padding-left: 1%;
}

.LoadingIcon {
  width: 100%;
  height: 100%;
  display: inline-flex;
  justify-content: center;
  margin-top: 2%;
}
