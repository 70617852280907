.chatListContainer {
  background-color: #f4f3f8;
  display: flex;
  justify-content: flex-start;
  min-width: 300px;
  max-width: 400px;
  height: 100%;
  border-radius: 10px;
  overflow: scroll;
  flex-direction: column;

  @media (max-width: 768px) {
    min-height: 50vh;
  }
}

.headerContainer {
  display: flex;
  align-items: center;
  padding: 10px;
}

.headerContainer span {
  font-size: 1em;
  font-weight: bold;
  margin-right: 10px;
}

.headerContainer input {
  background-color: #f4f3f8;
  padding: 5px 30px 5px 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  flex-grow: 1;
  width: 80%;
  position: relative;
  font-size: 1em;
}

.listGroupContainer {
  width: 100%;
  margin: 2px;
  align-items: top;
}

.listItemContainer {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

.dark {
  background-color: #2a2f62;
}

.dark .headerContainer input {
  background-color: #2a2f62;
  color: white;
}
