.formItem {
  margin-bottom: 15px;
  width: 100%;
  max-width: 400px;
}

.formItemField {
  height: 40px;
  position: relative;
}

.dropDown {
  width: 100%;
  height: 100%;
  background-color: #1c1f3c;
  border-radius: 10px;
}

.toggle {
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.customDropdownMenu {
  max-height: 250px; /* Limit dropdown height */
  overflow-y: auto;
  background-color: #1c1f3c;
  border-radius: 10px;
  width: 100%;
}

.customDropdownItem {
  display: flex;
  align-items: center;
  padding: 8px;
  color: white;
  cursor: pointer;
  background-color: #1c1f3c;
  white-space: normal; /* Allow text wrapping */
}

.customDropdownItem:hover {
  background-color: #2a2d5c;
}

.checkboxContainer {
  display: flex;
  align-items: center;
  gap: 8px;
}

.checkbox {
  margin: 0;
}

.checkboxLabel {
  color: white;
  word-wrap: break-word;
  max-width: 100%;
  white-space: nowrap; /* Allow full text to be visible */
}

.formItem input {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  border: none;
  color: white;
  padding: 0px 10px;
  background-color: #1c1f3c;
}

.formItem input:focus {
  outline: none !important;
  border: 1px solid rgb(40, 63, 77);
  box-shadow: 0 0 2px #1f6abb;
}

.textIcon {
  margin-bottom: 10px;
}

.textIcon span {
  margin-left: 5px;
}
