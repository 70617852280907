.platform-data {
  margin: 20px;
}

.stats-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}

.stat-card {
  background: #f7f7f7;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
  box-sizing: border-box;
}

.stat-card h3 {
  margin-bottom: 10px;
  font-size: 18px;
  font-style: sans-serif;
  color: #333;
}

.stat-card p {
  margin: 0;
  font-size: 24px;
  font-weight: bold;
  color: #007bff;
}

.title {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.innertitle {
  width: 420px;
  padding: 10px;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  border-radius: 10px;
  background-color: white;
  color: #333;
  border: 1px solid #333;
}

.chart-container {
  width: 25%;
  margin: 20px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.chart-container-map {
  width: 40%;
  margin: 20px auto;
  display: flex;
  align-items: center;
}

.chart-container h3 {
  text-align: center;
  margin-bottom: 10px;
}

.cahrtFlex {
  display: flex;
}
