.themeButton {
  background-color: transparent;
  border: none;
  width: 40px;
  height: 40px;
}

.themeButton svg {
  width: 100%;
  height: 100%;
}
