.mapLegend {
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 5px;
  border-radius: 8px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);

  display: flex;
  flex-direction: column;
}

.mapLegendItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.mapLegendItem p {
  margin: 0;
}

.mapLegendItem span {
  width: 30px;
  height: 2px;
}
