.BotContainer {
  display: flex;
  width: 100%;
  height: 90%;
  flex-direction: column;
  font-family: "Roboto", sans-serif;
  padding-top: 0px;
}

.ColContainer {
  background-color: #282c4d;
  border-radius: 16px;
  flex-direction: row;
  display: inline-flex;
  padding: 3%;
  flex-grow: 1;
  overflow: auto;
}

.BotContainerCol {
  margin-right: 10%;
  display: inline-flex;
  flex: 1;
  flex-direction: column;
  flex-wrap: nowrap;
}

.BotName {
  font-size: 1.6em;
  font-weight: bold;
  margin-right: 0.8%;
}

.BotIcon {
  color: "red";
}

.Status {
  margin-left: 1.5%;
}

.Field {
  width: 100%;
  font-size: 1em;
  font-weight: bold;
  display: inline-flex;
}

.SectionHeaderContainer {
  display: flex;
  align-items: center;
  margin-bottom: 4%;
}

.SectionHeader {
  color: #19dccd;
  font-size: 1.5em;
  font-weight: bold;
  margin-right: 5%;
}

.FieldTitle {
  font-weight: bold;
  width: 60%;
}

.FieldContent {
  font-weight: normal;
}

.EditBotButton {
  background-color: transparent;
  color: #46a6ff;
  border: none;
  height: auto;
  width: auto;
  justify-content: center;
  text-align: center;
  display: flex;
  margin-top: 0.5%;
  font-size: 1em;
  &:hover {
    color: #b3daff !important;
  }
}

.BotHeader {
  display: inline-flex;
  align-items: center;
  margin-bottom: 0.5%;
}
