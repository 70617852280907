a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
  margin-left: 1.5%;
}

.navBarPanel {
  z-index: 1000;
  height: 100vh;
  width: 60vw;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(var(--bs-emphasis-color-rgb));
  padding: 10px;
}

.navBarPanelHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.navBarSignOut {
  background-color: #19dccd;
  border: none;
  color: black;
  width: 100%;
  padding: 5px;
  border-radius: 10px;
  margin-top: 10px;
}

.navBarPanel h1 {
  margin-top: 10px;
  color: white;
}

.navMenuAndLogo {
  display: flex;
  align-items: center;
}

.navMenuAndLogo .sg-logo,
.navBarPanelHeader .sg-logo {
  margin-left: 20px;
}

.navBarMobile {
  margin-bottom: 20px;
}

html {
  font-size: 14px;
}

@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

.box-shadow {
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}