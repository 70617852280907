.faq-container {
  width: 85%;
  margin: 0 auto;
}
.faqheader {
  text-align: start;
  font-size: 36px;
  margin-right: 20px;
  color: white;
}

.faq-item {
  margin-bottom: 40px;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
}

.faq-question {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  font-size: 22px;
  font-weight: bold;
}
.faq-search-bar {
  background-color: #f4f3f8;
  padding: 5px 70px 5px 10px;
  border: 1px solid #ddd;
  border-radius: 5px;

  margin-left: 10px;
  width: 100%;
  position: relative;
  font-size: 1em;
}
.faq-question span {
  flex-grow: 1;
  margin-right: 10px;
}

.faq-toggle-button {
  background: none;
  border: none;
  font-size: 34px;
  color: white;
  cursor: pointer;
  padding: 0 10px;
}

.faq-answer {
  margin-top: 10px;
  padding-left: 20px;
  font-size: 16px;
  color: white;
}
.center {
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding-bottom: 20px;
}
