.welcomeVideo {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}

.welcomeVideo video {
  width: 100%;
  height: 100%;
  object-fit: fill;
}

@media (max-width: 600px) {
  .welcomeVideo {
    height: auto;
  }

  .welcomeVideo video {
    object-fit: contain;
  }

  .numberSection {
    bottom: 10px;
    border-radius: 8px;
  }

  .item {
    margin: 0;
  }

  .item span {
    font-size: 16px;
    letter-spacing: 3px;
    margin: 0;
  }

  .item p {
    font-size: 12px;
    letter-spacing: 1px;
    margin: 0;
  }
}
