.custom-input {
  width: 818px;
  min-height: 89px;
  max-height: 100px;
  padding: 10px 15px;
  background-color: #282c4d;
  border: none;
  border-radius: 10px;
  color: #ffffff;
  outline: none;
  margin-bottom: 10px;
  margin-top: 10px;
}
.EditBotContainer {
  width: 100%;
  display: flex;
}
.navigateBox {
  display: flex;  
  align-items: center;
  margin-bottom: 5px;
}
.navigateBtn {
  background-color: transparent;
  border: none;
  color: #46a6ff;
  cursor: pointer;
  margin: 0px;
}
.navigateBtn:hover {
  text-decoration: underline;
}
.EditContainer1 {
  width: 400px;
  height: 170px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.EditSpanFlex {
  display: flex;
  align-items: center;
}

.custom-input::placeholder {
  color: #aaa;
  opacity: 1;
}

.custom-input:focus {
  box-shadow: 0 0 5px rgba(255, 255, 255, 0.5);
}

.button {
  background-color: #384098;
  --bs-btn-active-bg: #aeb4ff;
  padding: 10px 15px;
  border: none;
  border-radius: 10px;
  color: #ffffff;
  outline: none;
  margin-bottom: 10px;
  margin-top: 10px;
}

.custom-div {
  padding-left: 1%;
  margin-left: 40px;
}

.button-container {
  display: flex;
  justify-content: start;
  align-items: center;
}

.save-button,
.exit-button {
  width: 280px;
  height: 51px;
  border: none;
  font-size: 16px;
  color: #fff;
  cursor: pointer;
  outline: none;
  margin-right: 100px;
  border-radius: 10px;
  margin-bottom: 20px;
  margin-top: 20px;
}

.save-button {
  background-color: #384098;
}

.save-button:hover {
  background-color: #2433a3;
}

.exit-button {
  background-color: #1c1d2b;
}

.exit-button:hover {
  background-color: #18191f;
}

.custom-ghost-button {
  background-color: #282c4d;
  border: none;
  border-radius: 10px;
  color: #ffffff;
  margin-bottom: 20px;
}

.ghost-button {
  background: transparent;
  border: 2px solid #ffffff;
  border-radius: 10px;
  color: #ffffff;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-bottom: 20px;
  margin-top: 20px;
  width: 140px;
}

.ghost-button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.border {
  height: 100%;
  width: 1px;
  background-color: white;
  margin-right: 20px;
}
