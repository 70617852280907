/* Provide sufficient contrast against white background */
:root {
  --background-color: #ffffff;
  --text-color: #15135b;
}

[data-theme="dark"] {
  --background-color: #0a0e37;
  --text-color: #ffffff;
}

body {
  background-color: var(--background-color);
  color: var(--text-color);
  overflow-x: hidden;
  max-width: 100vw;
}

a {
  color: #0366d6;
}

code {
  color: #e01a76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.map {
  width: 100%;
  height: 100%;
}

.sg-logo {
  padding-right: 10px;
  cursor: pointer;
}

.user-text {
  display: flex;
  justify-content: space-around;
  font-size: 20px;
  font-family: "Roboto", sans-serif;
  color: #fff;
}

.user-text:hover {
  color: #fff;
  cursor: pointer;
}

.head {
  background-color: #02041a;
}

.canvas {
  margin-right: 20px;
}

.bots-panel-container {
  height: 500px;
  overflow-y: auto;
}

/* .container {
  margin-left: 3%;
  margin-right: 0;
} */

.modal-body,
.modal-footer,
.modal-header {
  color: white !important;
  background-color: #1c1f3c;
}

.uni-button {
  background-color: #2a2f62;
  border: none;
  border-radius: 10px;
  color: white;
  padding: 5px;
  width: 13%;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  overflow: hidden;
}

.uni-button-selected {
  background-color: #23263f;
  font-weight: bold;
}

.form-control {
  color: white !important;
  background-color: #1c1f3c !important;
}

.form-control:active {
  color: white !important;
  background-color: #1c1f3c;
}

::placeholder {
  color: grey !important;
  opacity: 1; /* Firefox */
}

::-ms-input-placeholder {
  /* Edge 12 -18 */
  color: grey !important;
}

.universal-nav {
  display: flex;
  /* equal height of the children */
  justify-content: space-evenly;
}

.edit-organization {
  display: flexbox;
  flex-direction: row;
  align-content: space-around;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 40px;
}

.org-info {
  display: flexbox;
  padding-left: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius: 10px;
  background: #282c4d;
  margin-right: 0;
  width: 85%;
  justify-self: left;
  padding-bottom: 10px;
}

.input-name {
  margin-top: 1rem;
  margin-bottom: 0;
}
.org-edit-box {
  width: 90%;
  border: #1c1f3c;
  color: #fff;
  border-radius: 10px;
  height: 35px;
  background-color: #1c1f3c;
}

.address-edit-box {
  width: 90%;
  border: #1c1f3c;
  color: #fff;
  border-radius: 10px;
  height: 75px;
  background-color: #1c1f3c;
}

.description-edit-box {
  width: 90%;
  border: #1c1f3c;
  color: #fff;
  border-radius: 10px;
  height: 75px;
  background-color: #1c1f3c;
}

.org-nav {
  display: flexbox;
  flex-direction: row;
}

.info-head {
  display: flex;
  width: 100%;
}

.info-text {
  font-weight: bold;
  width: 30%;
  margin-right: 41%;
}

.charts {
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
  height: 10%;
  justify-content: space-between;
}

.panel-container {
  width: "100%";
  display: flex;
}

.panel-container > * {
  flex: 1;
  max-width: 33.33%;
  height: 500px;
  overflow-y: auto;
}

.charts img {
  min-width: 100%;
  max-width: 100%;
  border-radius: 12px;
}

.chart-title {
  border-bottom: 0.5px solid white;
}

.chart-covers {
  margin-bottom: 10px;
  display: flex;
  padding: 10px;
  background-color: #2a2f62;
  border-radius: 10px;
  width: 24%;
}

.light-theme,
.light-theme > div {
  background-color: #f4f3f8 !important;
  color: var(--text-color) !important;
}

.light-theme .dropdown-toggle {
  color: var(--text-color);
}

.light-theme input {
  background-color: #fff;
  color: var(--text-color);
}

.light-theme textarea {
  background-color: #fff;
  color: var(--text-color);
}
.light-theme.paragraph {
  background-color: transparent !important;
  color: var(--text-color);
}
.light-theme.faqheader {
  background-color: transparent !important;
  color: var(--text-color);
  text-align: start;
  font-size: 36px;
  margin-right: 20px;
  color: white;
}

.org-edit-container {
  display: flexbox;
  justify-content: space-between;
  justify-items: normal;
}

.nodataImg {
  height: 100%;
  width: 100%;
}
.nodataImg img {
  height: 180px;
}
.no-data {
  height: 100%;
  margin-bottom: 10px;
  width: 24%;
}
.drogba {
  height: 370px;
}
@media (min-width: 1700px) {
  .drogba {
    height: 420px;
  }
}

@media (max-width: 1300px) {
  .drogba {
    height: 300px;
  }
}

@media (max-width: 992px) {
  .drogba {
    height: 240px;
  }
}

/* Style for smaller screens */
@media (max-width: 750px) {
  .charts {
    display: flex;
    width: 100%;
    flex-direction: column;
  }
  .drogba {
    height: auto;
  }
  .panel-container {
    display: flex;

    width: 100%;
    align-items: center;
    gap: 20px;
    flex-direction: column;
  }

  .panel-container .next {
    width: 80%;
  }
  .nodataImg img {
    height: 300px;
  }
  .no-data {
    height: 100%;
    margin-bottom: 10px;
    width: 80%;
  }
  .chart-covers {
    margin-bottom: 20px;
    width: 80%;
  }
}

.blur {
  min-width: 100;
  max-width: 100%;
  max-height: 100%;
  backdrop-filter: blur(100%);
}

.user-info-head {
  display: flex;
  width: 100%;
}

.user-title {
  display: flexbox;
  width: 100%;
}

.user-container {
  display: flexbox;
  padding: 20px;
  border-radius: 10px;
  width: 100%;
  background: #282c4d;
  padding-bottom: 10px;
  justify-self: right;
}

.user-table {
  display: flexbox;
  width: 100%;
  justify-self: right;
}

.user-head {
  display: flexbox;
  height: 20%;
  width: 100%;
}

.add-user {
  border-radius: 5px;
  justify-self: right;
  width: 200px;
  height: 30px;
  text-align: center;
  background-color: #31387d;
  border: none;
  color: white;
}

.table-data {
  border-bottom: 0.1px solid white;
}

.org-drop {
  display: inline-flex;
  position: absolute;
  left: 28%;
}

.data-load {
  background-color: #2a2f62;
  border: none;
  border-radius: 10px;
  color: white;
  padding: 5px 50px;
  width: 13%;
  text-align: center;
  text-decoration: none;
  display: inline-block;
}

.modal-title,
.form-title {
  color: white;
}

.rightContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 200px;
  margin: 0px;
}

.login {
  color: #19dccd;
  margin-bottom: 0;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  text-decoration: none;
}

.login:hover {
  cursor: pointer;
  text-decoration: underline;
}

.modal-box,
.modal-box:checked,
.modal-box:focus,
.modal-box:active {
  background-color: transparent;
}

/* .modal-box:hover {
  background-color: white;
  color: black;
} */

.home {
  margin: 0em 3% 2em 3%;
  font-family: "Roboto", Helvetica;
}

.org-name-container {
  margin-left: 7px;
}

/* Required for Map component */
.leaflet-container {
  width: 100%;
  height: 100%;
}

.nav-button {
  cursor: pointer;
  color: #0366d6;
}

.nav-button:hover {
  color: white;
  text-decoration: underline;
}

/* createBotPage */

/* Common styles for both dropdowns */
.dropdownStyle {
  width: 250px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}

/* Specific styles for the second dropdown */
.dropdownStyle2 {
  width: 250px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}

.drop2 {
  margin-top: 3px;
  width: 250px;
  height: 35px;
  background-color: #1c1f3c;
  border-radius: 10px;
}

.btn.show {
  color: #19dccd;
}

.dropdown-toggle {
  color: #19dccd;
}

.dropItem {
  color: white;
  background-color: #1c1f3c !important;
}
.dropdown-item {
  color: white;
  background-color: #1c1f3c !important;
}

.dropdown-item:hover {
  color: white;
  background-color: #141737 !important;
}

.dropItem:hover {
  color: white;
  background-color: #141737 !important;
}

.inputContainer {
  display: flex;
  flex-direction: column;
}
.inputContainer input {
  width: 250px;
  height: 35px;
  border-radius: 10px;
  border: none;
  color: white;
  margin-top: 3px;
  padding: 0px 10px;
  background-color: #1c1f3c;
}
.inputContainer input:focus {
  outline: none !important;
  border: 1px solid rgb(40, 63, 77);
  box-shadow: 0 0 2px #719ece;
}

.buttonBox {
  width: 260px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.buttonBox .requestBtn {
  background-color: #384098;
  padding: 5px 10px;

  width: 120px;
  border-radius: 5px;
  border: none;
  color: white;
  font-size: 1rem;
  transition: 0.1s ease-in;
}
.buttonBox .requestBtn:hover {
  opacity: 0.9;
}

/*  */
.custom-modal {
  margin-top: 40px;
  color: black;
}
