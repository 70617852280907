.chatPanelContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.chatBubble {
  width: auto;
  max-width: 50%;
  padding: 10px;
  margin: 0 5px;
  word-wrap: break-word;
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.dateText {
  display: block;
  margin-top: 8px;
  color: #f5f5f5;
}

.buttonContainer {
  display: flex;
  justify-content: space-between;

  @media (max-width: 768px) {
    margin-left: 10px;
    width: auto;
  }
}

.messageBoxInput {
  width: 100%;
  position: relative;
  height: 150px;
  color: #f5f5f5 !important;
  overflow: auto;
  margin-top: 10px;
  margin-bottom: 5px;
  border-radius: 5px;
}

.messageBoxInput textarea {
  position: absolute;
  padding: 10px;
  width: 100%;
  height: 100%;
  border: none;
  box-sizing: border-box;
  resize: none;
  color: #f5f5f5;
  border-radius: 5px;
  overflow-y: auto;
}

.fileUpload {
  position: absolute;
  right: 5px;
  top: 5px;
  height: 25px;
  z-index: 1000;
}

.fileContainer {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.fileUpload input {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 1000;
}

.fileUpload img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.previewImg {
  width: 50px;
  height: 50px;
  position: absolute;
  left: 5px;
  bottom: 5px;
  cursor: pointer;
}

.iconButton {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.iconButtonCamera {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 4px;
}

@media (max-width: 768px) {
  .Typography {
    margin-left: 5px !important;
  }

  .messageBoxInput {
    width: auto;
    margin: 10px;
  }
}
