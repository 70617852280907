.UniversalNavButton {
  background-color: #2a2f62;
  font-weight: normal;
  border: none;
  border-radius: 10px;
  color: white;
  padding: 5px;
  width: 13%;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  overflow: hidden;
  cursor: pointer;

  &.active {
    background-color: #23263f;
    font-weight: bold;
  }

  @media (max-width: 480px) {
    &.ms-hiddenMdDown {
      display: none !important;
    }
  }
  @media (min-width: 481px) and (max-width: 640px) {
    &.ms-hiddenLgUp {
      display: none !important;
    }
  }

  @media (min-width: 641px) and (max-width: 1024px) {
    &.ms-hiddenLgUp {
      display: none !important;
    }
  }
  @media (min-width: 1025px) and (max-width: 1366px) {
    &.ms-hiddenLgUp {
      display: none !important;
    }
  }
  @media (min-width: 1367px) and (max-width: 1920px) {
    &.ms-hiddenLgUp {
      display: none !important;
    }
  }
  @media (min-width: 1921px) {
    &.ms-hiddenLgUp {
      display: none !important;
    }
  }
}
